<template>
  <div style="text-align: center">
    <a-button type="link" @click="handlePageJump">请到新标签页查看</a-button>
  </div>
</template>

<script>
import { trackRequest } from '@/utils/track';

export default {
  methods: {
    handlePageJump() {
      trackRequest(this.$route, 'jump_to_template_editor', {});
      window.open(process.env.VUE_APP_TEMPLATE_EDITOR, '_blank');
    },
  },
  mounted() {
    setTimeout(() => {
      this.handlePageJump();
    }, 100);
  },
};
</script>

<style></style>
